import React from "react";
import { Flex, Text, Box } from "@chakra-ui/react";

const LeaderBoard = ({ quizTitle, data }) => {
  const userId = JSON.parse(localStorage.getItem("userData")).id;
  const score = data.data;
  return (
    <>
      <Box color="#FFFFFF">
        <Text fontSize={16} lineHeight="16px" mb="4px" textAlign="center">
          Leaderboard
        </Text>
        <Text fontSize={24} fontWeight={700} lineHeight="24px" mb="28px" textAlign="center">
          {quizTitle}
        </Text>
        <Flex flexDirection="column" alignItems="center" maxH="276px" overflowY="scroll" width="100%" className="leaderboard" margin={0}>
          {score?.users.map((item, index) => {
            const { username, pivot } = item;
            const { score, user_id } = pivot;
            return (
              <Flex
                minH="46px"
                alignItems="center"
                pl="10px"
                pr="20px"
                fontSize={[16, 18]}
                width={["100%", "319px"]}
                background={userId === user_id ? "#2F429F" : ""}
                fontWeight={userId === user_id ? 700 : 400}
                margin={0}
                marginRight="0px"
                style={
                  userId === user_id
                    ? {
                        position: "sticky",
                        bottom: 0,
                      }
                    : {}
                }
                key={user_id}
              >
                <Text mr="25px" textAlign="right" width="30px">
                  {index + 1}
                </Text>
                <Text fontWeight={300} mr="20px">
                  {username}
                </Text>
                <Text ml="auto" fontWeight={300}>
                  {score}
                </Text>
              </Flex>
            );
          })}
        </Flex>
      </Box>
    </>
  );
};

export default LeaderBoard;
