import {
  Box,
  Container,
  Flex,
  Heading,
  Text,
  HStack,
  Image,
} from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import IHubLogo from "../assets/images/iHub.png";
import OnlineEtiquetteLogo from "../assets/images/online_etiquette.png";
import { ShareIcon } from "../components/Icons";
import {
  HomeChatBubbleOne,
  HomeChatBubbleTwo,
} from "../components/Illustrations";
import { BubbleButton } from "../components/Buttons";
import HomeIllustration from "../assets/images/HomeIllustration.png";
import Share from "../components/Share";

import { useHistory } from "react-router-dom";

const Home = () => {
  const history = useHistory();

  const startQuiz = () => {
    history.push("/sections");
  };

  return (
    <Box>
      <Helmet>
        <meta charSet="utf-8" />
        <title>My Title</title>
        <link rel="canonical" href="http://mysite.com/example" />
        <style type="text/css">{`
        body {
          background: url('/bg.png'), #3449a5;
          background-repeat: no-repeat;
          background-attachment: fixed;
          background-position: center bottom; 
          background-size: 'contain';
        }
    `}</style>
      </Helmet>
      {/* desktop */}
      <Container
        maxW="1440px"
        display={["none", "none", "none", "flex"]}
        justifyContent="space-between"
        flexDirection="column"
        minHeight="100vh"
      >
        <Flex
          justifyContent="space-between"
          alignItems="center"
          marginTop="28px"
        >
          <Heading fontSize={"28px"} color="white">
            #OnlineEtiquette
          </Heading>
          {/* <Button
            // onClick={onClick}
            minWidth={"219px"}
            _hover={{ background: "transparent" }}
            height={"47px"}
            rounded={"81px"}
            background="transparent"
            border="1px solid #fff"
          >
            <Heading color="white" fontSize={14} fontWeight="700">
              SUBSCRIBE FOR UPDATES
            </Heading>
          </Button> */}
        </Flex>
        <Flex
          justifyContent="center"
          position="relative"
          paddingTop="100px"
          paddingBottom="60px"
        >
          <Box position="absolute" left="190px">
            {/* top bubble */}
            <Box position="relative" width="300px">
              <HomeChatBubbleOne />
              <Text
                color="white"
                fontSize="18px"
                fontWeight="700"
                position="absolute"
                top="36px"
                left="51px"
                lineHeight="22px"
              >
                With modern smartphones, gadgets and the growth of broadband
                technology, accessing the internet has never been so easy.
              </Text>
            </Box>

            <Box position="relative" width="300px" top="31px" left="-80px">
              <HomeChatBubbleTwo />
              <Text
                color="white"
                fontSize="18px"
                fontWeight="700"
                position="absolute"
                top="36px"
                left="51px"
                lineHeight="22px"
              >
                The world is truly a global village and streaming, chatting,
                gaming, publishing, etc, are now all a click away. However, this
                ease of access has also led to addiction, fear and violence. It
                has also modified traditional ways of viewing acceptable
                behaviour and safety.
              </Text>
            </Box>
          </Box>
          <Image src={HomeIllustration} />

          <Box position="absolute" right="20px">
            <Image
              position="relative"
              top="-85px"
              left="-85px"
              src={OnlineEtiquetteLogo}
              width="475.74px"
              height="223.9px%"
            />
            <Text
              width="307px"
              color="white"
              fontWeight="700"
              fontSize="22px"
              lineHeight="27px"
              marginBottom="33px"
            >
              Let's jump right in and explore several critical issues around the
              use of the internet.
            </Text>
            <BubbleButton buttonType="startQuiz" onClick={startQuiz} />
          </Box>
        </Flex>

        {/* footer */}
        <Flex
          marginBottom="28px"
          justifyContent="space-between"
          alignItems="center"
          fontSize="14px"
          color="#B3BBFF"
        >
          <Text fontWeight="600" width="30%">
            Copyright 2020 © iHub
          </Text>
          <HStack width="40%" justifyContent="center">
            <Text>Powered by</Text>
            <Image src={IHubLogo} width="56px" />
          </HStack>

          <Flex width="30%" justifyContent="flex-end">
            <Share />
          </Flex>
        </Flex>
      </Container>
      {/* mobile */}
      <Box
        display={["block", "block", "block", "none"]}
        background="linear-gradient(180deg, #4762B7 0%, #2E419F 100%)"
        height="100vh"
      >
        <Container
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height="100%"
        >
          <Flex
            justifyContent="space-between"
            paddingX="2%"
            marginTop={25}
            alignItems="center"
          >
            <Heading fontSize={"16px"} color="white">
              #OnlineEtiquette
            </Heading>
            <HStack color="white" spacing={2} alignItems="center">
              <ShareIcon />{" "}
              <Text fontWeight="600" fontSize={14}>
                Share
              </Text>
            </HStack>
          </Flex>
          <Box
            position="relative"
            display="flex"
            flexDir="column"
            justifyContent="center"
            alignItems="center"
          >
            <Image
              position="absolute"
              top="-80px"
              src={OnlineEtiquetteLogo}
              width="324px"
            />

            <Image position="relative" src={HomeIllustration} height="300px" />

            <BubbleButton buttonType="startQuizLong" onClick={startQuiz} />
          </Box>

          {/* footer */}
          <Flex
            marginBottom="28px"
            justifyContent="center"
            alignItems="center"
            fontSize="14px"
            color="#B3BBFF"
          >
            <HStack justifyContent="center">
              <Text>Powered by</Text>
              <Image src={IHubLogo} width="56px" />
            </HStack>
          </Flex>
        </Container>
      </Box>
    </Box>
  );
};

export default Home;
