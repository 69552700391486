import {
  Text,
  Box,
  Center,
  Input,
  Flex,
  Spacer,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Spinner
} from "@chakra-ui/react";
import { BubbleButton } from "../components/Buttons";

export const ChooseUsernameModal = ({
  suggested,
  isOpen,
  onChange,
  value,
  onClick,
  error,
  loading
}) => {
  return (
    <>

      <Modal isOpen={isOpen} size="xl" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize={["20px", "38px"]} fontStyle="normal" fontWeight="bold">
            Choose a unique nickname
          </ModalHeader>
          <ModalBody>
            <Box
              border={`3px solid ${error ? "#E0201F" : "#064C8C"}`}
              borderRadius="5px"
            >
              <Flex alignItems="center">
                <Input
                  placeholder="Username"
                  border="none"
                  fontSize="28px"
                  pl="36px"
                  fontWeight="bold"
                  onChange={(e) => onChange(e)}
                  value={value}
                />
                <Spacer />
                <Center py="17px" pr="28px">
                  {
                    loading ? <Spinner /> :
                    <BubbleButton
                      buttonType="chooseUsernameContinue"
                      onClick={onClick}
                    />
                  }
                </Center>
              </Flex>
              <Flex
                bgColor={error ? "#FFA2A1" : "rgba(6, 76, 140, 0.4)"}
                px="36px"
                fontSize="16px"
                color={error ? "#E0201F" : ""}
                alignItems="center"
                h="48px"
              >
                {error ? (
                  <Text>{error}</Text>
                ) : (
                  <Text>
                    {" "}
                    Eg:{" "}
                    <Text
                       as="span"
                      color="#064C8C"
                      /*fontSize="16px"*/ fontStyle="normal"
                      fontWeight="bold"
                    >
                      {suggested}
                    </Text>{" "}
                  </Text>
                )}
              </Flex>
            </Box>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
