import React from "react";
import { Grid, Flex, Box } from "@chakra-ui/react";

const ProgressBar = ({ total, current }) => {
  const arr = Array.from(Array(total).keys());
  return (
    <Grid
      templateColumns={`repeat(${total}, 1fr)`}
      background="#F1F1F1"
      height={["14.8px", "21px", "21px", "21px"]}
      width={["100%", "464px"]}
      borderRadius="20px"
    >
      {arr.map((index) => {
        let dotColor = "#C6C6C6";
        if (current === index) dotColor = "#2B874A";
        if (index < current) dotColor = "#FFFFFF";
        return (
          <Flex
            pr={["2.9px", "4.2px", "4.2px", "4.2px"]}
            borderRightRadius={index >= current ? "20px" : ""}
            alignItems="center"
            justifyContent="flex-end"
            background={index <= current ? "#4EDB7D" : ""}
            _first={{ borderLeftRadius: "20px" }}
            key={index}
            position="relative"
          >
            <Box
              height={["8.6px", "12px", "12px", "12px"]}
              width={["8.6px", "12px", "12px", "12px"]}
              background={dotColor}
              borderRadius="50%"
              zIndex={3}
            ></Box>
            {index <= current && (
              <Box
                position="absolute"
                top={["2.82px", "4px", "4px", "4px"]}
                height={["2.11px", "3px", "3px", "3px"]}
                left={index === 0 ? ["7.75px", "11px", "11px", "11px"] : 0}
                right={
                  current === index ? ["10.57px", "15px", "15px", "15px"] : 0
                }
                background="#6AEB95"
                borderRadius="20px"
              ></Box>
            )}
          </Flex>
        );
      })}
    </Grid>
  );
};

export default ProgressBar;
