import "./App.scss";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Fonts } from "./Fonts";
import Home from "./views/Home";
import Notfound from "./views/Notfound";
import QuizQuestion from "./views/QuizQuestion";
import Sections from "./views/Sections";
import ScoreBoard from "./views/ScoreBoard";

const colors = {
  brand: {
    primary: "#3449A5",
  },
};

const theme = extendTheme({
  colors,
  fonts: {
    heading: "Mikado",
    body: "Gilroy",
  },
});

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Fonts />
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/questions/:id/:title" component={QuizQuestion} />
          <Route exact path="/sections" component={Sections} />
          <Route exact path="/scoreboard" component={ScoreBoard} />
          <Route component={Notfound} />
        </Switch>
      </Router>
    </ChakraProvider>
  );
}

export default App;
