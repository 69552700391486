import { Image, Box, Heading, Flex, Text, Link } from "@chakra-ui/react";

const Notfound = () => {
  return (
    <Flex
      width="100vw"
      height="100vh"
      justifyContent="center"
      alignItems="center"
    >
      <Box maxW={400} width="90%" marginX="auto" textAlign="center">
        <Image
          src="https://media.giphy.com/media/6uGhT1O4sxpi8/giphy.gif"
          width="100%"
        />
        <Heading fontSize={[24, 28]} marginTop={10}>
          Ooops! Looks like you're lost
        </Heading>
        <Text marginTop={5}>The page you're looking for is not available</Text>
        <Box maxW={140} width="100%" marginX="auto" marginTop={10}>
          <Link href="/">Return home</Link>
        </Box>
      </Box>
    </Flex>
  );
};

export default Notfound;
