import React, { useState, useEffect } from "react";
import { FacebookIcon, TwitterIcon, WhatsappIcon, LinkedIcon } from "./Icons";
import { HStack, Text, Menu, MenuButton, MenuList } from "@chakra-ui/react";
import {
  TwitterShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
} from "react-share";

import { ShareIcon } from "./Icons";

const Share = ({ scoreText, hideText }) => {
  const { host, protocol } = window.location;
  const url = `${protocol}//${host}`;

  const [description, setDescription] = useState(
    "Find out how Etiqu-culate you are? jump in and take the online etiquette trivia in a fun and engaging way"
  );

  useEffect(() => {
    if (scoreText) setDescription(scoreText);
  }, [scoreText]);

  return (
    <>
      <HStack spacing={8} display={["none", "flex"]}>
        {!hideText && (
          <Text fontSize="14px" color="#B3BBFF">
            Share
          </Text>
        )}
        <FacebookShareButton url={url} title={description}>
          <FacebookIcon />
        </FacebookShareButton>
        <TwitterShareButton url={url} title={description}>
          <TwitterIcon />
        </TwitterShareButton>
        <WhatsappShareButton url={url} title={description}>
          <WhatsappIcon />
        </WhatsappShareButton>
        <LinkedinShareButton url={url} title={description}>
          <LinkedIcon />
        </LinkedinShareButton>
      </HStack>
      <Menu>
        <MenuButton display={["flex", "none"]}>
          <HStack color="white" spacing={2} alignItems="center">
            <ShareIcon />{" "}
            <Text fontWeight="600" fontSize={14}>
              Share
            </Text>
          </HStack>
        </MenuButton>
        <MenuList background="#4762B7" minWidth="100px" p="15px">
          <HStack spacing={8}>
            <FacebookShareButton url={url} title={description}>
              <FacebookIcon />
            </FacebookShareButton>
            <TwitterShareButton url={url} title={description}>
              <TwitterIcon />
            </TwitterShareButton>
            <WhatsappShareButton url={url} title={description}>
              <WhatsappIcon />
            </WhatsappShareButton>
            <LinkedinShareButton url={url} title={description}>
              <LinkedIcon />
            </LinkedinShareButton>
          </HStack>
        </MenuList>
      </Menu>
    </>
  );
};

export default Share;
