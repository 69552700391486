import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Grid,
  Flex,
  Text,
  Stack,
  Heading,
  Circle,
  Image,
  CircularProgress,
  CircularProgressLabel,
  useDisclosure,
  Spinner
} from "@chakra-ui/react";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import { OutlineButton, QuestionButton } from "../components/Buttons";
import { optionStyles, buttonContainerStyles } from "../utils/quizStyles";
import QuizQuestionRight from "../components/QuizQuestionRight";
import default_check from "../assets/svg/default-check.svg";
import correct_check from "../assets/svg/correct-check.svg";
import correct_hollow_check from "../assets/svg/correct-hollow-check.svg";
import wrong_check from "../assets/svg/wrong-check.svg";
import selected_check from "../assets/svg/selected-check.svg";
import ProgressBar from "../components/ProgressBar";
import { ChooseUsernameModal } from "../components/Modals";
import { useHistory, useParams } from "react-router-dom";
import { baseUrl } from "../utils/url";

const QuizQuestion = () => {
  const { id, title } = useParams();
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [score, setScore] = useState(0);
  const [username, setUsername] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("userData")));
  const [token, setToken] = useState(localStorage.getItem('token'))

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [currentAnswer, setCurrentAnswer] = useState({});
  const [isCheckAnswer, setIsCheckAnswer] = useState(false);
  const [boxStyles, setBoxStyles] = useState({
    bg: buttonContainerStyles.defaultContainer,
    iconColor: buttonContainerStyles.defaultContainer.color,
    btnType: "default",
    boxText: "Select the appropriate option above",
    rightSection: "default",
  });

  const [quizData, setQuizData] = useState(null);

  const [submitLoading, setSubmitLoading] = useState(false);
  const [signupLoading, setSignupLoading] = useState(false);
  const [loading, setLoading] = useState(true);

  const checkSolution = () => {
    setIsCheckAnswer(true);
    if (currentAnswer.isCorrect) {
      setScore(score + 1);
      setBoxStyles({
        bg: buttonContainerStyles.correctContainer,
        iconColor: buttonContainerStyles.correctContainer.color,
        btnType: "correct",
        boxText: "Naaice! You choose the correct answer",
        rightSection: "correct",
      });
    }
    if (!currentAnswer.isCorrect) {
      setBoxStyles({
        bg: buttonContainerStyles.wrongContainer,
        iconColor: buttonContainerStyles.wrongContainer.color,
        btnType: "wrong",
        boxText:
          "Oops! That’s a wrong choice, the first option is the right answer.",
        rightSection: "wrong",
      });
    }
  };

  const nextQuestion = () => {
    if (currentQuestion < quizData.questions.length - 1) {
      setCurrentAnswer({});
      setIsCheckAnswer(false);
      setBoxStyles({
        bg: buttonContainerStyles.defaultContainer,
        iconColor: buttonContainerStyles.defaultContainer.color,
        btnType: "default",
        boxText: "Select the appropriate option above",
        rightSection: "default",
      });
      setCurrentQuestion(currentQuestion + 1);
    } else {
      submitAnswer();
    }
  };

  const { rightSection } = boxStyles;

  const enterUsername = (e) => {
    const { value } = e.target;
    setUsername(value);
    if(usernameError) setUsernameError("");
  }

  useEffect(() => {
    const fetchQuizQuestions = (id) => {
      axios
        .get(
          `${baseUrl}/quizzes/${id}/questions`
        )
        .then(({ data }) => {
          setQuizData(data[0]);
          setLoading(false);
          if (!token) onOpen();
        });
    };
    fetchQuizQuestions(id);
  }, [id, onOpen, token]);

  const signup = () => {
    if(!username || username.length < 2){
      setUsernameError("Enter a valid username");
      return
    }
    let match = /^[0-9a-zA-Z]+$/;
    if(!username.match(match)){
      setUsernameError("Username should contain just letters and/or numbers");
      return;
    }

    setSignupLoading(true);

    const body = {
      username,
    }
    axios.post(`${baseUrl}/users/signup`, body)
    .then(({data}) => {
      const { id, token: { token } } = data
      localStorage.setItem('token', token)
      const userData = {
        id,
        username
      }
      localStorage.setItem('userData', JSON.stringify(userData));
      setUser(userData);
      setToken(token);
      onClose();
      setSignupLoading(false)
    })
    .catch(err => {
      console.log(err);
      const message = err.response?.data[0]?.message
      if(message) setUsernameError(message);
      setSignupLoading(false);
    })
  }

  const submitAnswer = () => {
    setSubmitLoading(true);
    const body = {
      score: score * 10,
    }
    
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }

    axios.post(`${baseUrl}/quizzes/${id}/score`, body, config)
    .then(({data}) => {
      setSubmitLoading(false);
      history.push({
        pathname: "/scoreboard",
        state: {
          score,
          total: quizData.questions.length,
          quizId: id,
          quizName: title
        },
      });
    })
    .catch(err => {
      console.log(err);
      setSubmitLoading(false);
    })
  }

  return (
    <Box>
      <Grid
        gridTemplateColumns={["1fr", "1fr", "1fr", "0.65fr 0.35fr"]}
        height="100vh"
      >
        <Box
          background="#FFFFFF"
          padding={["24px", "24px", "30px 8%", "42px 60px 42px 135px"]}
          // overflowY="scroll"
          className="question-section"
        >
        {loading && (
          <Flex justifyContent="center" alignItems="center" h="100vh">
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </Flex>
        )}
          {quizData && (
            <>
              <Flex
                alignItems="center"
                justifyContent="space-between"
                mb="100px"
              >
                <OutlineButton caption="SECTIONS" onClick={() => history.push('/sections')} />
                <Box display={["none", "block"]} mx="24px">
                  <ProgressBar
                    total={quizData.questions.length}
                    current={currentQuestion}
                  />
                </Box>
                {/* <Flex justifyContent="center" alignItems="center" position="relative"> */}
                <CircularProgress
                  size={["40px", "55px"]}
                  color="#4EDB7D"
                  trackColor="#F1F1F1"
                  value={100}
                >
                  <CircularProgressLabel textAlign="center" width="auto">
                    <Circle
                      size={["24px", "36px"]}
                      bg="rgba(78, 219, 125, 0.3)"
                    >
                      <Text color="#4EDB7D" fontSize={14} fontWeight={700}>
                        {id}
                      </Text>
                    </Circle>
                  </CircularProgressLabel>
                </CircularProgress>
                {/* </Flex> */}
              </Flex>
              <Box
                display={["block", "none"]}
                mt="-84px"
                mb="28px"
                mx="-24px"
                py="16px"
                px="24px"
                borderTop="1px solid rgba(0, 0, 0, 0.1)"
                borderBottom="1px solid rgba(0, 0, 0, 0.1)"
              >
                <ProgressBar
                  total={quizData.questions.length}
                  current={currentQuestion}
                />
              </Box>
              <Box maxWidth="590px" mb={["30px", "100px"]}>
                <Text
                  color="#061458"
                  mb={["8px", "16px"]}
                  fontSize={["12px", "14px"]}
                  fontWeight="400"
                >
                  {quizData.title}
                </Text>
                <Heading
                  color="#061458"
                  mb={["24px", "60px"]}
                  fontSize={["20px", "38px"]}
                  fontWeight="700"
                >
                  {
                    quizData.questions.find(
                      (item, index) => index === currentQuestion
                    ).question
                  }
                </Heading>
                <Stack spacing={["24px", "33px"]}>
                  {quizData.questions
                    .find((item, index) => index === currentQuestion)
                    .answerOptions.map((answer) => {
                      let style = optionStyles.defaultOption;
                      let check = default_check;
                      if (answer.answerText === currentAnswer.answerText) {
                        style = optionStyles.selectedOption;
                        check = selected_check;
                      }
                      if (isCheckAnswer) {
                        if (
                          !currentAnswer.isCorrect &&
                          answer.answerText === currentAnswer.answerText
                        ) {
                          style = optionStyles.wrongOption;
                          check = wrong_check;
                        } else if (answer.isCorrect) {
                          style = optionStyles.correctOption;
                          check = correct_hollow_check;
                        }
                        if (
                          currentAnswer.isCorrect &&
                          answer.answerText === currentAnswer.answerText
                        ) {
                          style = optionStyles.correctOption;
                          check = correct_check;
                        }
                      }
                      return (
                        <Flex
                          style={style}
                          onClick={
                            currentAnswer.answerText && isCheckAnswer
                              ? null
                              : () => setCurrentAnswer(answer)
                          }
                          key={answer.answerText}
                        >
                          <Image
                            src={check}
                            alt=""
                            boxSize={["18px", "26px"]}
                          />
                          <Text
                            ml="16px"
                            fontSize={[12, 14]}
                            fontWeight={400}
                            opacity={0.8}
                          >
                            {answer.answerText}
                          </Text>
                        </Flex>
                      );
                    })}
                </Stack>
              </Box>
              <Flex
                style={boxStyles.bg}
                alignItems="center"
                // mx={["-24px", 0]}
                // mb={["-24px", 0]}
                borderRadius={["5px", "5px"]}
                flexWrap="wrap"
                height={["auto", "63px"]}
                pr={["16px", "10px"]}
                pl={["16px", "30px"]}
                py={["16px", 0]}
              >
                <Flex alignItems={["flex-start", "center"]} mr="auto">
                  <InfoOutlineIcon color={boxStyles.iconColor} mr="14px" />
                  <Text fontSize={[14, 16]} lineHeight="14px" fontWeight={600}>
                    {boxStyles.boxText}
                  </Text>
                </Flex>
                <Box width={["100%", "auto"]} mt={["16px", 0]}>
                  <QuestionButton
                    type={boxStyles.btnType}
                    disabled={!currentAnswer.answerText}
                    onClick={isCheckAnswer ? nextQuestion : checkSolution}
                    isLoading={submitLoading}
                  />
                </Box>
              </Flex>
            </>
          )}
        </Box>
        <QuizQuestionRight
          questionStatus={rightSection}
          hint={
            quizData &&
            quizData.questions.find((item, index) => index === currentQuestion)
              .hint
          }
          username={user?.username}
        />
      </Grid>
      <ChooseUsernameModal
        isOpen={isOpen}
        onClose={onClose}
        suggested="PlayerEtiquette1"
        onChange={enterUsername}
        value={username}
        onClick={signup}
        error={usernameError}
        loading={signupLoading}
      />
    </Box>
  );
};

export default QuizQuestion;
