import React from "react";
import { Box, Flex, Text, Heading, Tag, Image } from "@chakra-ui/react";
import man_unanswered from "../assets/svg/man-unanswered.svg";
import man_correct from "../assets/svg/man-correct.svg";
import man_wrong from "../assets/svg/man-wrong.svg";
import emoji_sad from "../assets/svg/emoji-sad.svg";
import emoji_wink from "../assets/svg/emoji-wink.svg";
import emoji_rockect_up from "../assets/svg/emoji-rocket-up.svg";
import emoji_rockect_down from "../assets/svg/emoji-rocket-down.svg";
import Share from "./Share";

const QuizQuestionRight = ({ questionStatus, hint, username }) => {
  return (
    <Box
      flexDirection="column"
      // justifyContent="space-between"
      alignItems="center"
      display={["none", "none", "none", "flex"]}
      background="url('/bg.png'), #3449a5"
      backgroundRepeat="no-repeat"
      backgroundAttachment="fixed"
      backgroundPosition="center top"
      backgroundSize="contain"
      height="100%"
      padding="51px 30px 36px 30px"
    >
      <Box mb="20px">
        <Share />
        <Text color="white" marginTop="69px" textAlign="center" fontSize="11px">
          {username && "Username"}
        </Text>
        <Heading
          textAlign="center"
          fontFamily="Gilroy"
          color="white"
          fontSize="32px"
          fontWeight="700"
        >
          {username}
        </Heading>
      </Box>
      {/* <Box height="30px" /> */}
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        flexGrow={1}
      >
        <Box paddingBottom="30px">
          <Box
            position="relative"
            background="white"
            width="328px"
            borderRadius="60px"
            p="26px 26px 26px 26px"
          >
            {questionStatus === "default" && (
              <>
                {hint ? (
                  <>
                    <Tag
                      bg="rgba(66, 91, 178, 0.2)"
                      borderRadius="60px"
                      height="43px"
                      p="12px 23px"
                      mb="21px"
                    >
                      <Text color="#3145A2" fontSize={16} fontWeight={600}>
                        {hint.tag}
                      </Text>
                    </Tag>
                    <Text color="#3145A2" fontSize={16} fontWeight={700}>
                      {hint.hintText}
                    </Text>
                  </>
                ) : (
                  <>
                    <Heading color="#3145A2" fontSize={36} fontWeight={700}>
                      Hmmm...tough question yea?
                    </Heading>
                  </>
                )}
              </>
            )}
            {questionStatus === "correct" && (
              <>
                <Heading color="#3145A2" fontSize={66} fontWeight={800}>
                  YESS!
                </Heading>
                <Flex alignItems="center">
                  <Heading color="#3145A2" fontSize={26} fontWeight={500}>
                    You are so <br /> Etiqu-culate
                  </Heading>
                  <Image src={emoji_wink} alt="" boxSize="42px" />
                  <Image src={emoji_rockect_up} alt="" boxSize="42px" />
                </Flex>
              </>
            )}
            {questionStatus === "wrong" && (
              <>
                <Heading color="#3145A2" fontSize={66} fontWeight={800}>
                  Oops...
                </Heading>
                <Flex alignItems="center">
                  <Heading color="#3145A2" fontSize={26} fontWeight={500}>
                    Not so <br /> Etiqu-culate
                  </Heading>
                  <Image src={emoji_sad} alt="" boxSize="42px" />
                  <Image src={emoji_rockect_down} alt="" boxSize="42px" />
                </Flex>
              </>
            )}
            <Box position="absolute" right="80px" bottom="-30px">
              <svg
                width="52"
                height="33"
                viewBox="0 0 52 33"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.0159 0.428731C14.044 10.7646 4.93369 23.128 3.87678e-05 28.0177C39.5966 33.2441 51.0503 9.43043 51.8275 -3.1297C36.962 -6.25018 7.98789 -9.90717 11.0159 0.428731Z"
                  fill="white"
                />
              </svg>
            </Box>
          </Box>
        </Box>

        <Image
          src={man_unanswered}
          alt=""
          className={
            questionStatus === "default"
              ? "illustration show"
              : "illustration hide"
          }
        />
        <Image
          src={man_correct}
          alt=""
          className={
            questionStatus === "correct"
              ? "illustration show"
              : "illustration hide"
          }
        />
        <Image
          src={man_wrong}
          alt=""
          className={
            questionStatus === "wrong"
              ? "illustration show"
              : "illustration hide"
          }
        />
      </Flex>
    </Box>
  );
};

export default QuizQuestionRight;
