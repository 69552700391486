import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Container,
  Flex,
  Heading,
  Text,
  Avatar,
  Grid,
  Image,
  VStack,
  Spinner,
} from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import Share from "../components/Share";
import { BubbleButton, TryAgainButton } from "../components/Buttons";
import LeaderBoard from "../components/LeaderBoard";

import avatar from "../assets/svg/avatar.svg";
import gold from "../assets/svg/gold.svg";
import silver from "../assets/svg/silver.svg";
import bronze from "../assets/svg/bronze.svg";
import { useHistory, useLocation } from "react-router-dom";
import { baseUrl } from "../utils/url";

const ScoreBoard = () => {
  const location = useLocation();
  const history = useHistory();

  const [showLeaderBoard, setShowLeaderBoard] = useState(false);
  const [score, setScore] = useState(null);
  const [leaderboard, setLeaderboard] = useState([]);
  const [quizTitle, setQuizTitle] = useState("");
  const [user, setUser] = useState({});
  const [sections, setSections] = useState([]);

  const [scoreLoader, setScoreLoader] = useState(false);

  const toggleLeaderBoard = () => {
    setShowLeaderBoard(!showLeaderBoard);
  };

  useEffect(() => {
    if (showLeaderBoard) {
      window.scrollTo({
        top: document.body.scrollHeight,
        left: 0,
        behavior: "smooth",
      });
    }
  }, [showLeaderBoard]);

  useEffect(() => {
    const {
      state: { quizId, quizName },
    } = location;

    setQuizTitle(quizName);
    const user = JSON.parse(localStorage.getItem("userData"));
    setUser(user);

    const token = localStorage.getItem('token');
    
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }

    const fetchScore = () => {
      setScoreLoader(true);
      axios
        .get(`${baseUrl}/score/${quizId}`, config)
        .then(({ data }) => {
          setScore(data);
          setScoreLoader(false);
        })
        .catch((e) => {
          console.log(e);
          setScoreLoader(false);
        });
    };
    const fetchLeaderBoard = () => {
      axios
        .get(`${baseUrl}/score/${quizId}/usersScore`, config)
        .then(({ data }) => {
          setLeaderboard(data);
        });
    };
    const fetchSections = () => {
      axios
        .get(`${baseUrl}/quizzes/all`)
        .then(({ data }) => {
          setSections(data);
        });
    };

    fetchScore();
    fetchLeaderBoard();
    fetchSections();
  }, [location]);

  const nextSection = () => {
    const nextId = Number(location.state.quizId) + 1;
    const next = sections.find(item => item.id === nextId) || sections[0];
    const {id, title} = next;
    history.push(`/questions/${id}/${title}`);
  }

  const medalData = {
    Gold: gold,
    Silver: silver,
    Bronze: bronze,
  };

  return (
    <Box
      background="linear-gradient(180deg, #4762B7 0%, #2E419F 100%)"
      overflowX="hidden"
    >
      <Box
        background="linear-gradient(180deg, #4762B7 0%, #2E419F 93.01%)"
        py={["20px", "20px", "20px", "30px"]}
        height={["70px", "70px", "70px", "120px"]}
        mx={["-5%"]}
        px={["5%"]}
        borderBottomLeftRadius="250%"
        borderBottomRightRadius="250%"
      >
        <Container maxW="1440px">
          <Flex justifyContent="space-between" alignItems="center">
            <Heading fontSize={["16px", "16px", "16px", "28px"]} color="white">
              #OnlineEtiquette
            </Heading>
            <Box display={["block", "block", "block", "none"]}>
              <Share scoreText={`I got ${score?.score} out of ${score?.total} in the online etiquette Trivia! Give it a shot?`}/>
            </Box>
            {/*  */}
            <Flex
              alignItems="center"
              display={["none", "none", "none", "flex"]}
            >
              <Text fontSize={20} fontWeight={700} color="#FFFFFF" mr="16px">
                {user?.username}
              </Text>
              <Avatar name="Mayowa1300" src={avatar} boxSize="32px" />
            </Flex>
          </Flex>
        </Container>
      </Box>
      <Container
        maxW="1440px"
        minHeight="100vh"
        marginTop={["-70px", "-100px"]}
        paddingTop={["70px", "100px"]}
        paddingBottom={[0, 0, 0, "100px"]}
      >
        {/* Spinner container */}
        {scoreLoader && (
          <Flex justifyContent="center" alignItems="center" h="90vh">
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </Flex>
        )}
        {/* Desktop view */}
        {score && (
          <Grid
            templateColumns="repeat(3, 1fr)"
            gap="20px"
            paddingTop="180px"
            display={["none", "none", "none", "grid"]}
          >
            <Box color="#FFFFFF" maxWidth="356px">
              <Heading fontSize={32} fontWeight={400}>
                Hoo-ray!
              </Heading>
              <Heading fontSize={58} fontWeight={900} mb="34px">
                You got {score?.medal}!
              </Heading>
              <Box h="1px" background="rgba(255, 255, 255, 0.1)" mb="22px" />
              <Text fontSize={24} mb="22px">
                You got a score of {score?.score} out of a possible total score
                of {score?.total}.
                <Box as="span" fontWeight={700}>
                  {" "}
                  That’s really Etiqu-culate!!!
                </Box>
              </Text>
              <Box h="1px" background="rgba(255, 255, 255, 0.1)" mb="22px" />
              <Text fontSize={16} mb="20px">
                Share your score with your friends and invite them to try out
                the quiz.
              </Text>
              <Share scoreText={`I got ${score?.score} out of ${score?.total} in the online etiquette Trivia! Give it a shot?`} hideText={true}/>
            </Box>
            <Box>
              <Flex justifyContent="center" mt="-100px">
                <Image src={medalData[score?.medal]} />
              </Flex>
              <Flex justifyContent="space-between" mt="60px">
                <BubbleButton buttonType="nextSection" onClick={nextSection} />
                <TryAgainButton onClick={() => history.goBack()} />
              </Flex>
            </Box>
            <Box>
              <LeaderBoard quizTitle={quizTitle} data={leaderboard} />
            </Box>
          </Grid>
        )}

        {/* Mobile and tablet view */}
        {score && (
          <Flex
            direction="column"
            alignItems="center"
            color="#FFFFFF"
            style={{ minHeight: "calc(100vh - 70px)" }}
            paddingTop="24px"
            display={["flex", "flex", "flex", "none"]}
          >
            <Heading fontSize={18} fontWeight={400}>
              Hoo-ray!
            </Heading>
            <Heading fontSize={40} fontWeight={900} mb="26px">
              You got {score?.medal}!
            </Heading>
            <Image src={medalData[score?.medal]} width="50%" height="auto" />
            <Text fontSize={14} textAlign="center" mt="16px">
              You scored of {score?.score} out of {score?.total}.
              <br />
              <Box as="span" fontWeight={700}>
                {" "}
                That’s really Etiqu-culate!!!
              </Box>
            </Text>
            <VStack spacing="16px" maxWidth="300px" mt="20px" mb="auto">
              <BubbleButton buttonType="nextSectionLong" onClick={nextSection} />
              <TryAgainButton
                buttonType="tryAgainLong"
                onClick={() => history.goBack()}
              />
            </VStack>
            <Box width="100%" marginTop="32px">
              <Flex
                justifyContent="space-between"
                height="54px"
                width="100%"
                alignItems="center"
                borderTop="0.5px solid rgba(255, 255, 255, 0.15)"
                bottom={0}
                onClick={() => toggleLeaderBoard()}
                cursor="pointer"
              >
                <Text fontSize={14} fontWeight={400}>
                  View Leaderboard
                </Text>
                {showLeaderBoard ? <ChevronUpIcon /> : <ChevronDownIcon />}
              </Flex>
              {showLeaderBoard && (
                <Box py="36px">
                  <LeaderBoard quizTitle={quizTitle} data={leaderboard} />
                </Box>
              )}
            </Box>
          </Flex>
        )}
      </Container>
    </Box>
  );
};

export default ScoreBoard;
