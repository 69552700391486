import { Global } from "@emotion/react";

export const Fonts = () => (
  <Global
    styles={`

    @font-face {
        font-family: "Gilroy";
        src: url("Gilroy/Gilroy-Black.woff2") format("woff2"),
            url("Gilroy/Gilroy-Black.woff") format("woff");
        font-weight: 900;
        font-style: normal;
        font-display: swap;
    }
        
    @font-face {
        font-family: "Gilroy";
        src: url("Gilroy/Gilroy-BlackItalic.woff2") format("woff2"),
            url("Gilroy/Gilroy-BlackItalic.woff") format("woff");
        font-weight: 900;
        font-style: italic;
        font-display: swap;
    }
        
    @font-face {
        font-family: "Gilroy";
        src: url("Gilroy/Gilroy-Bold.woff2") format("woff2"),
            url("Gilroy/Gilroy-Bold.woff") format("woff");
        font-weight: bold;
        font-style: normal;
        font-display: swap;
    }
        
    @font-face {
        font-family: "Gilroy";
        src: url("Gilroy/Gilroy-BoldItalic.woff2") format("woff2"),
            url("Gilroy/Gilroy-BoldItalic.woff") format("woff");
        font-weight: bold;
        font-style: italic;
        font-display: swap;
    }
        
    @font-face {
        font-family: "Gilroy";
        src: url("Gilroy/Gilroy-Extrabold.woff2") format("woff2"),
            url("Gilroy/Gilroy-Extrabold.woff") format("woff");
        font-weight: 800;
        font-style: normal;
        font-display: swap;
    }
        
    @font-face {
        font-family: "Gilroy";
        src: url("Gilroy/Gilroy-ExtraboldItalic.woff2") format("woff2"),
            url("Gilroy/Gilroy-ExtraboldItalic.woff") format("woff");
        font-weight: 800;
        font-style: italic;
        font-display: swap;
    }
        
    @font-face {
        font-family: "Gilroy";
        src: url("Gilroy/Gilroy-Heavy.woff2") format("woff2"),
            url("Gilroy/Gilroy-Heavy.woff") format("woff");
        font-weight: 900;
        font-style: normal;
        font-display: swap;
    }
        
    @font-face {
        font-family: "Gilroy";
        src: url("Gilroy/Gilroy-Light.woff2") format("woff2"),
            url("Gilroy/Gilroy-Light.woff") format("woff");
        font-weight: 300;
        font-style: normal;
        font-display: swap;
    }
        
    @font-face {
        font-family: "Gilroy";
        src: url("Gilroy/Gilroy-HeavyItalic.woff2") format("woff2"),
            url("Gilroy/Gilroy-HeavyItalic.woff") format("woff");
        font-weight: 900;
        font-style: italic;
        font-display: swap;
    }
        
    @font-face {
        font-family: "Gilroy";
        src: url("Gilroy/Gilroy-LightItalic.woff2") format("woff2"),
            url("Gilroy/Gilroy-LightItalic.woff") format("woff");
        font-weight: 300;
        font-style: italic;
        font-display: swap;
    }
        
    @font-face {
        font-family: "Gilroy";
        src: url("Gilroy/Gilroy-Medium.woff2") format("woff2"),
            url("Gilroy/Gilroy-Medium.woff") format("woff");
        font-weight: 500;
        font-style: normal;
        font-display: swap;
    }
        
    @font-face {
        font-family: "Gilroy";
        src: url("Gilroy/Gilroy-MediumItalic.woff2") format("woff2"),
            url("Gilroy/Gilroy-MediumItalic.woff") format("woff");
        font-weight: 500;
        font-style: italic;
        font-display: swap;
    }
        
    @font-face {
        font-family: "Gilroy";
        src: url("Gilroy/Gilroy-Regular.woff2") format("woff2"),
            url("Gilroy/Gilroy-Regular.woff") format("woff");
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }
        
    @font-face {
        font-family: "Gilroy";
        src: url("Gilroy/Gilroy-Semibold.woff2") format("woff2"),
            url("Gilroy/Gilroy-Semibold.woff") format("woff");
        font-weight: 600;
        font-style: normal;
        font-display: swap;
    }
        
    @font-face {
        font-family: "Gilroy";
        src: url("Gilroy/Gilroy-RegularItalic.woff2") format("woff2"),
            url("Gilroy/Gilroy-RegularItalic.woff") format("woff");
        font-weight: normal;
        font-style: italic;
        font-display: swap;
    }
        
    @font-face {
        font-family: "Gilroy";
        src: url("Gilroy/Gilroy-SemiboldItalic.woff2") format("woff2"),
            url("Gilroy/Gilroy-SemiboldItalic.woff") format("woff");
        font-weight: 600;
        font-style: italic;
        font-display: swap;
    }
        
    @font-face {
        font-family: "Gilroy";
        src: url("Gilroy/Gilroy-Thin.woff2") format("woff2"),
            url("Gilroy/Gilroy-Thin.woff") format("woff");
        font-weight: 100;
        font-style: normal;
        font-display: swap;
    }
        
    @font-face {
        font-family: "Gilroy";
        src: url("Gilroy/Gilroy-UltraLight.woff2") format("woff2"),
            url("Gilroy/Gilroy-UltraLight.woff") format("woff");
        font-weight: 200;
        font-style: normal;
        font-display: swap;
    }
        
    @font-face {
        font-family: "Gilroy";
        src: url("Gilroy/Gilroy-ThinItalic.woff2") format("woff2"),
            url("Gilroy/Gilroy-ThinItalic.woff") format("woff");
        font-weight: 100;
        font-style: italic;
        font-display: swap;
    }
        
    @font-face {
        font-family: "Gilroy";
        src: url("Gilroy/Gilroy-UltraLightItalic.woff2") format("woff2"),
            url("Gilroy/Gilroy-UltraLightItalic.woff") format("woff");
        font-weight: 200;
        font-style: italic;
        font-display: swap;
    }






    @font-face {
        font-family: "Mikado";
        src: url("Mikado/MikadoBlack-Italic.woff2") format("woff2"),
          url("Mikado/MikadoBlack-Italic.woff") format("woff");
        font-weight: 900;
        font-style: italic;
        font-display: swap;
    }
      
      @font-face {
        font-family: "Mikado";
        src: url("Mikado/MikadoBold-Italic.woff2") format("woff2"),
          url("Mikado/MikadoBold-Italic.woff") format("woff");
        font-weight: bold;
        font-style: italic;
        font-display: swap;
    }
      
      @font-face {
        font-family: "Mikado";
        src: url("Mikado/MikadoBold.woff2") format("woff2"),
          url("Mikado/MikadoBold.woff") format("woff");
        font-weight: bold;
        font-style: normal;
        font-display: swap;
    }
      
      @font-face {
        font-family: "Mikado";
        src: url("Mikado/MikadoLight-Italic.woff2") format("woff2"),
          url("Mikado/MikadoLight-Italic.woff") format("woff");
        font-weight: 300;
        font-style: italic;
        font-display: swap;
    }
      
      @font-face {
        font-family: "Mikado";
        src: url("Mikado/MikadoLight.woff2") format("woff2"),
          url("Mikado/MikadoLight.woff") format("woff");
        font-weight: 300;
        font-style: normal;
        font-display: swap;
    }
      
      @font-face {
        font-family: "Mikado";
        src: url("Mikado/MikadoBlack.woff2") format("woff2"),
          url("Mikado/MikadoBlack.woff") format("woff");
        font-weight: 900;
        font-style: normal;
        font-display: swap;
    }
      
      @font-face {
        font-family: "Mikado Ultra";
        src: url("Mikado/MikadoUltra.woff2") format("woff2"),
          url("Mikado/MikadoUltra.woff") format("woff");
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }
      
      @font-face {
        font-family: "Mikado";
        src: url("Mikado/MikadoRegular.woff2") format("woff2"),
          url("Mikado/MikadoRegular.woff") format("woff");
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }
      
      @font-face {
        font-family: "Mikado";
        src: url("Mikado/MikadoMedium-Italic.woff2") format("woff2"),
          url("Mikado/MikadoMedium-Italic.woff") format("woff");
        font-weight: 500;
        font-style: italic;
        font-display: swap;
    }
      
      @font-face {
        font-family: "Mikado";
        src: url("Mikado/MikadoRegular-Italic.woff2") format("woff2"),
          url("Mikado/MikadoRegular-Italic.woff") format("woff");
        font-weight: normal;
        font-style: italic;
        font-display: swap;
    }
      
      @font-face {
        font-family: "Mikado Ultra";
        src: url("Mikado/MikadoUltra-Italic.woff2") format("woff2"),
          url("Mikado/MikadoUltra-Italic.woff") format("woff");
        font-weight: normal;
        font-style: italic;
        font-display: swap;
    }
      
    @font-face {
        font-family: "Mikado";
        src: url("Mikado/MikadoMedium.woff2") format("woff2"),
          url("Mikado/MikadoMedium.woff") format("woff");
        font-weight: 500;
        font-style: normal;
        font-display: swap;
    }
    `}
  />
);
