import React from "react";
import { Box, Flex, Text, Image } from "@chakra-ui/react";
import { StartIcon } from './Icons';

const SectionCard = ({ number, name, illustration, clickAction }) => {
  return (
    <Box width="270px" minWidth="270px" background="#FFFFFF" borderRadius="4px">
      <Box borderRadius="4px" background="#FFEB55" height="104px">
        <Box borderRadius="4px" background="#F8C81F" height="16px"></Box>
        <Flex alignItems="center" px="20px" height="88px" position="relative">
          <Text
            fontSize={68}
            fontWeight={700}
            color="#F8C81F"
            lineHeight="68px"
          >
            {number}
          </Text>
          <Image src={illustration} position="absolute" bottom="10px" right="60px" marginRight="-20%"/>
        </Flex>
      </Box>
      <Flex alignItems="center" height="124px" px="20px" borderBottom="1px solid #F3F3F3">
        <Text fontSize={28} fontWeight={700} color="#3145A2">
          {name}
        </Text>
      </Flex>
      <Flex alignItems="center" justifyContent="flex-end" height="78px" px="20px">
        <Flex alignItems="center" cursor="pointer"  onClick={clickAction}>
          <Text fontSize={28} fontWeight={700} color="#0CB946" mr="12px">Start Here</Text>
          <StartIcon />
        </Flex>
      </Flex>
    </Box>
  );
};

export default SectionCard;
