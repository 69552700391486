export const optionStyles = {
  defaultOption: {
    alignItems: "center",
    border: "1px solid #E0E0E0",
    borderRadius: "5px",
    padding: "20px 36px 20px 16px",
    cursor: "pointer",
    color: "#18131A",
  },
  selectedOption: {
    alignItems: "center",
    border: "2px solid #1F5AF4",
    borderRadius: "5px",
    padding: "20px 36px 20px 16px",
    cursor: "pointer",
    color: "#1F5AF4",
    background: "rgba(31, 90, 244, 0.1)",
  },
  correctOption: {
    alignItems: "center",
    border: "2px solid #26B656",
    borderRadius: "5px",
    padding: "20px 36px 20px 16px",
    cursor: "pointer",
    color: "#26B656",
    background: "rgba(38, 182, 86, 0.1)",
  },
  wrongOption: {
    alignItems: "center",
    border: "2px solid #E0201F",
    borderRadius: "5px",
    padding: "20px 36px 20px 16px",
    cursor: "pointer",
    color: "#E0201F",
    background: "rgba(224, 32, 31, 0.1)",
  },
};

export const buttonContainerStyles = {
  defaultContainer: {
    // height: "63px",
    background: "#7EBBF2",
    // borderRadius: "5px",
    color: "#064C8C",
    // paddingLeft: "30px",
    // paddingRight: "10px",
  },
  correctContainer: {
    // height: "63px",
    background: "#CAF4D8",
    // borderRadius: "5px",
    color: "#26B656",
    // paddingLeft: "30px",
    // paddingRight: "10px",
  },
  wrongContainer: {
    // height: "63px",
    background: "#FFA2A1",
    // borderRadius: "5px",
    color: "#E0201F",
    // paddingLeft: "30px",
    // paddingRight: "10px",
  },
};
