import React, { useRef, useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import {
  Box,
  Container,
  Flex,
  Heading,
  HStack,
  Spinner,
} from "@chakra-ui/react";
import Share from "../components/Share";
import { RoundButton } from "../components/Buttons";
import SectionCard from "../components/SectionCard";
import { baseUrl } from "../utils/url";

import illustration_1 from "../assets/svg/illustration_1.svg";
import illustration_2 from "../assets/svg/illustration_2.svg";
import illustration_3 from "../assets/svg/illustration_3.svg";
import illustration_4 from "../assets/svg/illustration_4.svg";
import illustration_5 from "../assets/svg/illustration_5.svg";
import illustration_6 from "../assets/svg/illustration_6.svg";

const illustrations = {
  1: illustration_1,
  2: illustration_2,
  3: illustration_3,
  4: illustration_4,
  5: illustration_5,
  6: illustration_6,
};

const Sections = () => {
  const sectionsRef = useRef(null);
  const history = useHistory();

  const [scroll, setScroll] = useState(0);
  const [sectionsWidth, setSectionsWidth] = useState(0);
  const [scrollWidth, setScrollWidth] = useState(0);
  const [sections, setSections] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchSections();
    const width = sectionsRef.current?.offsetWidth;
    const scrollWidth = sectionsRef.current?.scrollWidth;
    setSectionsWidth(width);
    setScrollWidth(scrollWidth);
  }, [sectionsRef]);

  const scrollRight = () => {
    const distance = scroll + sectionsWidth;
    sectionsRef.current.scrollTo({
      top: 0,
      left: distance,
      behavior: "smooth",
    });
    setScroll(distance <= scrollWidth ? distance : sectionsWidth);
  };

  const scrollLeft = () => {
    const distance = scroll - sectionsWidth;
    sectionsRef.current.scrollTo({
      top: 0,
      left: distance,
      behavior: "smooth",
    });
    setScroll(distance >= 0 ? distance : 0);
  };

  const fetchSections = () => {
    axios
      .get(`${baseUrl}/quizzes/all`)
      .then(({ data }) => {
        setSections(data);
        const scrollWidth = sectionsRef.current?.scrollWidth;
        setScrollWidth(scrollWidth);
        setLoading(false);
      });
  };

  const select = (id, title) => {
    history.push(`/questions/${id}/${title}`);
  };

  return (
    <Box
      background="linear-gradient(180deg, #4762B7 0%, #2E419F 100%)"
      overflowX="hidden"
    >
      <Box
        background="linear-gradient(180deg, #4762B7 0%, #2E419F 93.01%)"
        padding="30px"
        height={["80px", "120px"]}
        mx={["-5%"]}
        px={["5%"]}
        borderBottomLeftRadius="250%"
        borderBottomRightRadius="250%"
      >
        <Container maxW="1440px">
          <Flex justifyContent="space-between" alignItems="center">
            <Heading fontSize={["16px", "28px"]} color="white">
              #OnlineEtiquette
            </Heading>
            <Share />
          </Flex>
        </Container>
      </Box>
      <Container
        maxW="1440px"
        minHeight="100vh"
        marginTop={["-70px", "-100px"]}
        paddingTop={["70px", "100px"]}
        paddingBottom="100px"
      >
        <Flex justifyContent="space-between" marginTop={["35px", "83px"]}>
          <Heading color="#FFFFFF" fontSize={[40, 68]} fontWeight={900}>
            Sections{" "}
            <Box as="span" fontSize={[18, 38]} fontWeight={400}>
              ({sections.length})
            </Box>
          </Heading>
          <HStack spacing="22px" display={["none", "flex"]}>
            <RoundButton
              type="left"
              clickAction={() => scrollLeft()}
              disabled={scroll <= 0}
            />
            <RoundButton
              type="right"
              clickAction={() => scrollRight()}
              disabled={!(scroll + sectionsWidth <= scrollWidth)}
            />
          </HStack>
        </Flex>
        {loading && (
          <Flex justifyContent="center" alignItems="center" h="40vh">
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </Flex>
        )}
        <Box
          paddingTop="82px"
          paddingBottom="62px"
          overflowX="scroll"
          className="quiz-sections"
          ref={sectionsRef}
        >
          {sections.length > 0 && (
            <HStack spacing="30px">
              {sections.map(({ id, title }) => (
                <SectionCard
                  key={id}
                  number={id}
                  name={title}
                  illustration={illustrations[id]}
                  clickAction={() => select(id, title)}
                />
              ))}
            </HStack>
          )}
        </Box>
        {sections.length > 0 && (
          <Flex justifyContent="space-between" display={["flex", "none"]}>
            <RoundButton
              type="left"
              clickAction={() => scrollLeft()}
              disabled={scroll <= 0}
            />
            <RoundButton
              type="right"
              clickAction={() => scrollRight()}
              disabled={!(scroll + sectionsWidth <= scrollWidth)}
            />
          </Flex>
        )}
      </Container>
    </Box>
  );
};

export default Sections;
